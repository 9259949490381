import {MediaImage} from "./media--image";
import {PRODUCT_TEASER_IMAGE_STYLE} from "../../../types/image-styles";
import {DrupalMedia} from "next-drupal";
import {Dictionary, DrupalVideoMedia} from "../../../types/hygiena-types";
import classNames from "classnames";
import dynamic from "next/dynamic";
import {useContext} from "react";
import {DictionaryContext} from "../../../context/dictionary-context";
import {useRouter} from "next/router";
import {Heading} from "../../atoms/heading";
import {FormattedText} from "../../formatted-text";
import {isYoutubeURL} from "../../organisms/dynamic-video-player";
import SectionHeading from "../../organisms/section-heading";
import {Link} from "../../atoms/link";
import {absoluteURL} from "../../../lib/absolute-url";
import Head from "next/head";
const TrainingVideosSlider = dynamic(() => import("../../organisms/slider").then((mod) => mod.TrainingVideosSlider));
const DynamicVideoPlayer = dynamic(() => import("../../organisms/dynamic-video-player").then((mod) => mod.DynamicVideoPlayer));


/**
 * The Video Page component.
 *
 * @param video
 * @param additionalContent
 * @param props
 * @constructor
 */
export function MediaVideo({video, additionalContent, ...props}: {
  video: DrupalVideoMedia,
  additionalContent: {
    relatedVideos: DrupalVideoMedia[]
  },
}) {
  const t = useContext<Dictionary>(DictionaryContext);
  const isYouTube = isYoutubeURL(video?.field_media_oembed_video ?? "");

  // Compose structured data.
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "VideoObject",
  };
  const updatedTime = video?.created?.split("T")?.[0];
  const metaDescription = video?.metatag?.filter(meta => meta?.attributes?.name === 'description')?.[0];
  const metaTitle = video?.metatag?.filter(meta => meta?.attributes?.name === 'title')?.[0];
  if (metaDescription?.attributes?.content) {
    structuredData["description"] = metaDescription.attributes.content;
  }
  if (updatedTime) {
    structuredData["uploadDate"] = updatedTime;
  }
  if (metaTitle?.attributes?.content) {
    structuredData["name"] = metaTitle.attributes.content;
  }
  if (video?.field_video_thumbnail?.bf_image?.uri?.url) {
    structuredData["thumbnailUrl"] = [
      video.field_video_thumbnail.bf_image.uri.url
    ];
  }
  if (video?.field_video_thumbnail?.image?.uri?.url) {
    structuredData["thumbnailUrl"] = [
      video.field_video_thumbnail.image.uri.url
    ];
  }
  else if (video?.thumbnail?.uri?.url?.length) {
    structuredData["thumbnailUrl"] = [
      absoluteURL(video.thumbnail.uri.url),
    ];
  }

  const playerProps = isYouTube ? {} : {
    config: {
      playerOptions: {
        title: true,
        byline: true,
        portrait: false,
        playsinline: true,
        autopause: true,
        fullscreenEnabled: true,
      }
    },
    volume: 1,
  };

  return (
    <>
      <Head>
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{__html: JSON.stringify(structuredData)}}
        />
      </Head>
      <article className="container px-6 lg:px-0 py-10 mx-auto" {...props}>
        <DynamicVideoPlayer width={'90%'} height={'70vh'} controls fullscreen url={video.field_media_oembed_video}
                            style={{maxWidth: '90%', width: '90%', margin: 'auto', marginBottom: "3rem"}} {...playerProps} ssr={true} />
        <div className="mb-12">
          {video?.field_video_categories?.name && (
            <div className="text-primary font-bold pb-2">{video.field_video_categories.name}</div>
          )}
          <Heading headerType={1} className="font-light !text-[40px]">
            {video?.field_video_display_title?.length ? video.field_video_display_title : video.name}
          </Heading>
          {video?.field_video_description?.processed?.length ? (
            <div className="mt-4 text-lg">
              <FormattedText processed={video.field_video_description.processed}/>
            </div>
          ) : <></>}
        </div>
        {additionalContent?.relatedVideos?.length ? (
          <>
            <SectionHeading title={`${t?.video_library?.related_videos}`}/>
            <TrainingVideosSlider videos={additionalContent.relatedVideos} usePath={true}/>
            <div className="text-right pr-2 text-primary">
              <Link href={"/video-library"}>{t?.video_library?.back_to_video_library}</Link>
            </div>
          </>
        ) : <></>}
      </article>
    </>
  )
}

export function MediaVideoTeaser({video, openModal, openModalContent}: {
  video: DrupalVideoMedia,
  openModal?: Function | null,
  openModalContent?: Function | null,
}) {
  const t = useContext<Dictionary>(DictionaryContext);
  const {locale} = useRouter();

  const isYouTube = isYoutubeURL(video?.field_media_oembed_video ?? "");

  const playerProps = isYouTube ? {} : {
    config: {
      playerOptions: {
        title: true,
        byline: true,
        portrait: false,
        playsinline: true,
        autopause: true,
        fullscreenEnabled: true,
      }
    },
    volume: 1,
    playing: true,
    style: {maxWidth: '100%', width: '100%'},
  };

  /**
   * Opens the Video modal and plays the video.
   */
  const playVideo = async (url?: string) => {
    if (url?.length && openModal && openModalContent) {
      openModal(true);
      openModalContent(
        <div className={classNames("mb-6 w-auto h-full self-end max-w-full")}>
          <DynamicVideoPlayer width={'100%'} height={'100%'} controls fullscreen url={url}
                              style={{maxWidth: '100%', width: '100%'}} {...playerProps}/>
        </div>
      )
    }
  }

  return (
    <div
      className="bg-white transition-all duration-500 rounded-md box-shadow-md hover:box-shadow-lg overflow-hidden cursor-pointer"
      onClick={() => playVideo(video.field_media_oembed_video ?? "")}>
      <div className="h-84 relative overflow-hidden">
        {video?.field_video_thumbnail && (
          <MediaImage
            loading={"lazy"}
            // @ts-ignore
            media={video.field_video_thumbnail}
            imageStyle={PRODUCT_TEASER_IMAGE_STYLE}
            style={{maxHeight: "75%", objectFit: "cover", width: "100%"}}
            sizes="405px"
            className="relative h-full flex scale-image-hover overflow-hidden"
          />
        )}
        {!video?.field_video_thumbnail && video?.thumbnail?.uri?.url && (
          <MediaImage
            loading={"lazy"}
            // @ts-ignore
            media={{
              type: "media--image",
              image: {
                uri: video.thumbnail.uri,
                blur_image: video?.thumbnail?.blur_image,
                resourceIdObjMeta: video.thumbnail?.resourceIdObjMeta,
              },
            } as DrupalMedia}
            imageStyle={PRODUCT_TEASER_IMAGE_STYLE}
            style={{maxHeight: "75%", objectFit: "cover", width: "100%"}}
            sizes="405px"
            className="relative h-full flex scale-image-hover overflow-hidden"
          />
        )}
        <div
          className="absolute bottom-0 bg-hygienaLight/80 pt-5 pb-4 w-full min-h-[120px] backdrop-blur-sm rounded-b-md">
          <div className="px-6 pb-1 text-primary font-bold">
            {video?.field_video_categories?.[0] ? (
              <>{video.field_video_categories[0].name}</>
            ) : <></>}
            {video?.field_video_categories?.["name"] ? (
              <>{video.field_video_categories["name"]}</>
            ) : <></>}
          </div>
          <div className="px-6 text-black text-xl tracking-tight font-light">
            {video?.field_video_display_title?.length ? video.field_video_display_title : video.name}
          </div>
        </div>
        {video?.langcode && video.langcode !== locale && (
          <div className="px-2.5 py-1 font-bold absolute top-5 right-5 bg-primary rounded-full text-white text-sm">
            {t?.languages[video.langcode]}
          </div>
        )}
      </div>
    </div>
  )
}